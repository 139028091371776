@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: garetRegular;
  src: url("./content/font/Garet-Regular.ttf");
}

@font-face {
  font-family: garetBold;
  src: url("./content/font/Garet-Bold.ttf");
}


body:not(:has(.game)) {
  margin: 0;
  padding: 0;
  background-color: #76b34c;
  background-image: url('./logo.jpg');
  background-size: contain;
  backdrop-filter: brightness(0.7) blur(4px);
  background-repeat: no-repeat;
  background-position: center;
  font-family: garetBold, garetRegular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 700ms ease;
}

.font-normal {
  font-family: garetRegular, sans-serif;
}

:root {
  --white: #fff;
  --black: #000;
  --darkgray: #252525;
  --gray: #8a8a8a;
  --lightgray: #e5e5e5;
  --purple: #44489a;
  --primary: #76b34c;
  --blue: #5cabff;
  --green: #00bf63;
  --pink: #924adb;
  --stats: #c1ff72;
  --red: #ff5757;
  --sprintBg: #252525;
}

.input {
  @apply px-4 py-2 border-none bg-[var(--lightgray)] text-black rounded-[25px] outline-[var(--primary)]
}
